require("packs/filters");
require("packs/bookmarks");
require("packs/home_firebase");
require("packs/link_tracker");

import { setEvents } from "packs/bookmarks";

document.addEventListener("DOMContentLoaded", () => {
  var loading = false;
  var page = 2;
  window.addEventListener('scroll', function() {
    if (!loading && isElementInViewport(document.getElementById("append_pagination"))) {
      loading = true;
      document.getElementById("pagination-animation").classList.remove("hidden");
      var params = new URLSearchParams(window.location.search);
      var paramsObject = {page: page}
      for (const [key, value] of params) {
          paramsObject[key] = value;
      }
      $.ajax({
        url: '/hot',
        method: 'GET',
        dataType: 'script',
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
        data: paramsObject,
        success: function (response) {
          loading = false;
          document.getElementById("pagination-animation").classList.add("hidden");
          page += 1;
          setEvents();
          setTimeout(recalculateHeight, 100);
        }, error: function (error) {
          loading = false;
          document.getElementById("pagination-animation").classList.add("hidden");
        }
      });
    }
  });

  function changeCardView(view) {
    let currentUrl = new URL(window.location.href);
    let displayViewParam = 'display_view';
    let displayViewValue = view;
    currentUrl.searchParams.set(displayViewParam, displayViewValue);
    window.location.href = currentUrl;
  }
  document.querySelectorAll('.change-display-view').forEach(display => {
    display.addEventListener('click', function() {
      changeCardView(this.dataset.view);
    });
  })

  document.querySelectorAll('.change-section').forEach(display => {
    display.addEventListener('click', function() {
      changeSection(this.dataset.path);
    });
  })

  function changeSection(path) {
    let currentSearchParams = new URLSearchParams(window.location.search);
    let currentUrl = new URL(path, window.location.origin);
    currentSearchParams.forEach((value, key) => {
      currentUrl.searchParams.set(key, value);
    });
    window.location.href = currentUrl.toString();
  }
});

function isElementInViewport(el) {
  if (!el || el.style.display === 'none') {
    return false;
  }
  
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function recalculateHeight() {
  $(document).height();
}