let merchantList = document.getElementById("merchant-list");
let searchInput = document.getElementById("filter-search");
let clearButton = document.getElementById("clear-btn");
let searchItems = document.querySelectorAll(".search-item");

let openFilter = document.getElementById("open-merchant-filter");
let closeFilter = document.getElementById("close-merchant-filter");
let merchantfilter = document.getElementById("merchant-filter");
let selectedMerchants = document.getElementById("SelectedMerchants");
let listOfMerchats = [];

getListofMerchants();

async function getListofMerchants() {
  await fetch("/all_active_stores.json")
  .then((response) => response.json())
  .then((data) => {
    listOfMerchats = data;
  })
  .catch((error) => console.error("Error fetching merchants:", error));
}

openFilter.addEventListener("click", () => {
  if (window.innerWidth < 768) {
    let body = document.body;
    body.classList.add("overflow-hidden");
  }
  merchantfilter.classList.remove("hidden");
  merchantfilter.classList.add("block");
  // Fetch active merchants
  const selectedMerchantsArray = selectedMerchants
    ? JSON.parse(selectedMerchants.getAttribute("data-selected-merchants"))
    : [];
  // Clear existing merchant list
  merchantList.innerHTML = "";

  listOfMerchats.forEach((merchant) => {
    // Check if the merchant is selected
    const isSelected = selectedMerchantsArray.some((selected) => {
      return selected.permalink === merchant.permalink;
    });
    renderMerchant(merchant, isSelected);
  });
  if (selectedMerchantsArray.length > 0) {
    selectedMerchantsArray.forEach(merchant => {
      document.getElementById(`merchant-${merchant}`).checked = true;
    })
    display_arr = selectedMerchantsArray;
    renderMerchantChip(selectedMerchantsArray);
  }
});

closeFilter.addEventListener("click", () => {
  if (window.innerWidth < 768) {
    let body = document.body;
    body.classList.remove("overflow-hidden");
  }
  merchantfilter.classList.remove("block");
  merchantfilter.classList.add("hidden");
});

searchInput.addEventListener("input", function () {
  const searchTerm = this.value.trim();
  if (searchTerm.length >= 2) {
    fetch(`/all_active_stores.json?term=${encodeURIComponent(searchTerm)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const checkedMerchants = Array.from(
          merchantList.querySelectorAll('input[type="checkbox"]:checked')
        ).map((checkbox) => ({
          permalink: checkbox.value,
          merchant_name: checkbox.dataset.merchantName,
        }));

        // Clear existing merchant list
        merchantList.innerHTML = "";

        // Render checked merchants first
        checkedMerchants.forEach((merchant) => {
          renderMerchant(merchant, true);
        });

        // Render unchecked merchants then
        if (data.length === 0) {
          const noResultDiv = document.createElement("div");
          noResultDiv.textContent = `${ document.getElementById("no-results-found").innerText } ${searchTerm}`;
          noResultDiv.style.paddingBottom = '10px';
          merchantList.prepend(noResultDiv);
        } else {
          data.forEach((merchant) => {
            const isChecked = checkedMerchants.some(
              (checkedMerchant) =>
                checkedMerchant.permalink === merchant.permalink
            );
            if (!isChecked) {
              renderMerchant(merchant, false);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching merchants:", error);
      });
  } else if (searchTerm.length == 0) {
    const checkedMerchants = Array.from(
      merchantList.querySelectorAll('input[type="checkbox"]:checked')
    ).map((checkbox) => ({
      permalink: checkbox.value,
      merchant_name: checkbox.dataset.merchantName,
    }));

    fetch("/all_active_stores.json")
      .then((response) => response.json())
      .then((data) => {
        // Clear existing merchant list
        merchantList.innerHTML = "";

        // Render checked merchants first
        checkedMerchants.forEach((merchant) => {
          renderMerchant(merchant, true);
        });

        // Render unchecked merchants
        data.forEach((merchant) => {
          const isChecked = checkedMerchants.some(
            (checkedMerchant) =>
              checkedMerchant.permalink === merchant.permalink
          );
          if (!isChecked) {
            renderMerchant(merchant, false);
          }
        });
      })
      .catch((error) => console.error("Error fetching merchants:", error));
  }
});

function renderMerchant(merchant, forceCheck = false) {
  var truncatedName;
  if (window.innerWidth < 768) {
    truncatedName = merchant.merchant_name.length > 30 ? merchant.merchant_name.slice(0, 10) + "..." : merchant.merchant_name;
  } else {
    truncatedName = merchant.merchant_name.length > 20 ? merchant.merchant_name.slice(0, 10) + "..." : merchant.merchant_name;
  }
  merchantList.innerHTML += `
        <div class="search-item pb-4">
          <input name= filters[merchant_ids][] type="checkbox" class="mr-2 text-primary focus:ring-0 add-merchant" id="merchant-${
            merchant.permalink
          }" value="${merchant.permalink}" data-merchant-name="${
    merchant.merchant_name
  }" ${forceCheck || isChecked(merchant.permalink) ? "checked" : ""}>
          <label for="merchant-${merchant.permalink}">${truncatedName}</label>
        </div>
      `;

  document.querySelectorAll(".add-merchant").forEach(function(merchant) {
    merchant.addEventListener("click", function() {
      addMerchantToDisplayList(this)
    })
  })
}

let display_arr = [];
function addMerchantToDisplayList(merchant) {
  let merchantName = merchant.dataset.merchantName;
  let merchantId = merchant.value;
  if (merchant.checked) {
    if (!display_arr.includes(merchantName + ":" + merchantId)) {
      display_arr.push(merchantName + ":" + merchantId);
    }
  } else {
    let preSelected = selectedMerchants ? JSON.parse(selectedMerchants.getAttribute("data-selected-merchants")) : [];
    let index = display_arr.indexOf(merchantName + ":" + merchantId);
    if (index > -1) {
      display_arr.splice(index, 1);
    } else if (preSelected.length > 0 && preSelected.indexOf(merchantId) != -1) {    
      display_arr.splice(preSelected.indexOf(merchantId), 1);
      preSelected.splice(preSelected.indexOf(merchantId), 1);
      document.getElementById(`merchant-${ merchantId }`).checked = false;
    }
  }


  renderMerchantChip(display_arr);
}

async function renderMerchantChip(merchants) {
  let content = "";
  for (let merchant of merchants) {
    let merchant_name
    if (merchant.indexOf(":") == -1) {
      merchant_name  = await getNameFromPermalink(merchant);
    } else {
      merchant_name  = await getNameFromPermalink(merchant.split(":")[1]);
    }
    content += `<div class="merchant-chip cursor-pointer mr-2 mb-1 py-1 px-2 rounded-full text-xs bg-secondary-icon-light dark:bg-secondary-icon-dark text-white dark:text-primary-text-light font-medium inline-block" data-merchant-id="${ (merchant.indexOf(":") == -1) ? merchant : merchant.split(":")[1] }">${ merchant_name }</div>`
  }
  if (display_arr.length == 0) {
    document.getElementById("selected-merchants").classList.remove("border-b", "border-light-border", "dark:border-dark-border");
  } else {
    document.getElementById("selected-merchants").classList.add("border-b", "border-light-border", "dark:border-dark-border");
  }

  document.getElementById("selected-merchants").innerHTML = content;
  
  
  document.querySelectorAll(".merchant-chip").forEach(function(merchant) {
    merchant.addEventListener("click", function() {
      removeMercahntChip(this);
    })
  })

}

function getNameFromPermalink(name) {
  return fetch(`/all_active_stores.json?term=${encodeURIComponent(name)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        return data[0].merchant_name;

      })
      .catch((error) => {
        console.error("Error fetching merchants:", error);
      });
}

function removeMercahntChip(merchant) {
  let content = merchant.textContent;
  let id = merchant.dataset.merchantId;
  let index = display_arr.indexOf(content + ":" + id);
  let preSelected = selectedMerchants ? JSON.parse(selectedMerchants.getAttribute("data-selected-merchants")) : [];
  if (index > -1) {
    display_arr.splice(index, 1);
    document.getElementById(`merchant-${ id }`).checked = false;
  } else if (preSelected.length > 0 && preSelected.indexOf(id) != -1) {    
    display_arr.splice(preSelected.indexOf(id), 1);
    preSelected.splice(preSelected.indexOf(id), 1);
    document.getElementById(`merchant-${ id }`).checked = false;
  }

  renderMerchantChip(display_arr);
}

function isChecked(merchantPermalink) {
  const checkbox = document.getElementById(`merchant-${merchantPermalink}`);
  return checkbox && checkbox.checked;
}
