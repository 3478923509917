
$(document).ready(function(){
  if($('#firb-con').data("basecon") != undefined){
    var firebaseConfig =  JSON.parse(window.atob($('#firb-con').data("basecon")));
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    var hp_initState = true;
    var hp_new_initState = true;


    if(window.location.pathname === "/new"){
      var database  = firebase.database();
      var hpNewRef  = database.ref("hp_new_refresh/deals");

      hpNewRef.limitToLast(1).on('child_added', function(snapshot) {
        if (hp_initState) {
            hp_initState = false;
        }else{
          $("#btn-auto-refresh").show()
        }
      });
    }else if(window.location.pathname === "/"){
      var database  = firebase.database();
      var hpRef  = database.ref("hp_refresh/deals");
      var hpNewRef = database.ref("hp_new_refresh/deals");

      hpRef.limitToLast(1).on('child_added',  function(snapshot) {
        if (hp_initState) {
          hp_initState = false;
        } else {
          $("#btn-auto-refresh").show()
        }
      });

      hpNewRef.limitToLast(1).on('child_added', function(snapshot) {
        if (hp_new_initState) {
          hp_new_initState = false;
        } else {
          var x = $('#new-deal-counter').text();
          if ( x != "") {
            y = parseInt(x)
            $("#new-deal-counter").html('<span class="deal-counter ml-2 text-sm min-w-max h-5 inline-flex px-2 bg-highlight-red justify-center items-center rounded-full text-white hover:text-white">' + ++y + '</span>');
          } else {
            $("#new-deal-counter").html('<span class="deal-counter ml-2 text-sm min-w-max h-5 inline-flex px-2 bg-highlight-red justify-center items-center rounded-full text-white hover:text-white">1</span>');
          }
        }
      });
    }else if(window.location.pathname === "/discussed"){
      var database  = firebase.database();
      var hpNewRef  = database.ref("hp_new_refresh/deals");


      hpNewRef.limitToLast(1).on('child_added', function(snapshot) {
        if (hp_new_initState) {
          hp_new_initState = false;
        } else {
          var x = $('#new-deal-counter').text();
          if ( x != "") {
            y = parseInt(x)
            $("#new-deal-counter").html('<i class="deal-counter">' + ++y + '</i>');
          } else {
            $("#new-deal-counter").html('<i class="deal-counter">1</i>');
          }
        }
      });
    }
  }
});

document.getElementById("btn-auto-refresh").addEventListener("click", function() {
  window.location.reload();
});